<template>
    <div>
        <ReturnTitleBar>添加租户</ReturnTitleBar>
        <div class="main-container">
            <Form :model="clientInfo" :label-width="80" style="width: 500px">
                <FormItem label="租户名称">
                    <Input v-model="clientInfo.clientName"></Input>
                </FormItem>
                <FormItem label="姓名">
                    <Input v-model="clientInfo.name"></Input>
                </FormItem>
                <FormItem label="手机">
                    <Input v-model="clientInfo.phone"></Input>
                </FormItem>
                <FormItem label="Email">
                    <Input v-model="clientInfo.email"></Input>
                </FormItem>
                <FormItem label="密码">
                    <Input v-model="clientInfo.password"></Input>
                </FormItem>
                <FormItem label="智能名片">
                    <i-switch size="large" v-model="clientInfo.bizCardEnable">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
                <FormItem>
                    <Button @click="save" type="primary">保存</Button>
                </FormItem>
            </Form>
        </div>
    </div>
</template>
<script>
import ReturnTitleBar from "@/components/ReturnTitleBar";
import { add, update, getInfo } from "@/api/client-info";

export default {
    components: {
        ReturnTitleBar,
    },
    created() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.getInfo();
        }
    },
    data() {
        return {
            id: 0,
            clientInfo: {
                clientName: "",
                name: "",
                phone: "",
                email: "",
                password: "",
                bizCardEnable: false
            },
        };
    },
    methods: {
        getInfo() {
            getInfo(this.id).then((response) => {
                this.clientInfo.clientName = response.clientName;
                this.clientInfo.name = response.name;
                this.clientInfo.phone = response.phone;
                this.clientInfo.email = response.email;
                this.clientInfo.bizCardEnable = response.bizCardEnable;
            });
        },
        save() {
            if (!this.clientInfo.clientName) {
                this.$Message.error("请输入租户名称！");
                return;
            }
            if (!this.clientInfo.name) {
                this.$Message.error("请输入姓名！");
                return;
            }
            if (!this.clientInfo.phone) {
                this.$Message.error("请输入手机！");
                return;
            }
            if (!this.clientInfo.email) {
                this.$Message.error("请输入Email！");
                return;
            }
            if (this.id > 0) {
                update(this.id, this.clientInfo).then(() => {
                    this.$Message.success("保存成功！");
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                });
            } else {
                if (!this.clientInfo.password) {
                    this.$Message.error("请输入密码！");
                    return;
                }
                add(this.clientInfo).then(() => {
                    this.$Message.success("添加成功！");
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                });
            }
        },
    },
};
</script>