import { render, staticRenderFns } from "./ReturnTitleBar.vue?vue&type=template&id=b6fbe114&scoped=true&"
import script from "./ReturnTitleBar.vue?vue&type=script&lang=js&"
export * from "./ReturnTitleBar.vue?vue&type=script&lang=js&"
import style0 from "./ReturnTitleBar.vue?vue&type=style&index=0&id=b6fbe114&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6fbe114",
  null
  
)

export default component.exports