import request from '@/util/request';
import qs from "qs";

export function getList(query) {
    return request({
        url: '/client-info',
        method: 'get',
        params: query
    });
};

export function add(params) {
    return request({
        url: '/client-info',
        method: 'post',
        data: qs.stringify(params)
    });
}

export function update(id, params) {
    return request({
        url: '/client-info/' + id,
        method: 'put',
        data: qs.stringify(params)
    });
}

export function getInfo(id) {
    return request({
        url: '/client-info/' + id,
        method: 'get'
    });
};

export function disable(id) {
    return request({
        url: '/client-info/disable',
        method: 'post',
        data: qs.stringify({id})
    });
}

export function enable(id) {
    return request({
        url: '/client-info/enable',
        method: 'post',
        data: qs.stringify({id})
    });
}

export function del(id) {
    return request({
        url: '/client-info/' + id,
        method: 'delete'
    });
}

export function getUserList(clientId) {
    return request({
        url: '/client-info/users/' + clientId,
        method: 'get'
    });
};

export function addUser(params) {
    return request({
        url: '/client-info/users/add',
        method: 'post',
        data: qs.stringify(params)
    });
};

export function delUser(id) {
    return request({
        url: '/client-info/delUser/' + id,
        method: 'delete'
    });
}